'use client';

import { VARIANT_COOKIE_PREFIX } from '@falkor/atreyu';
import Cookies from 'js-cookie';
import { useCallback, useEffect } from 'react';

/**
 * The variant cookie helper will observe the page for unload and modify the
 * variant cookie if there is a redirect present. The redirect is set on the
 * page variant.
 *
 * @returns null
 */
const VariantCookie = () => {
  const onBeforeUnload = useCallback(() => {
    const allCookies = Cookies.get();

    for (const key in allCookies) {
      if (key.startsWith(VARIANT_COOKIE_PREFIX) && key.endsWith('-variant')) {
        const redirectCookieName = key.replace('-variant', '-redirect');
        const redirect = Cookies.get(redirectCookieName);

        if (typeof redirect === 'string' && redirect.length > 0) {
          Cookies.set(key, redirect);
          Cookies.remove(redirectCookieName);
        }
      }
    }
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, [onBeforeUnload]);

  return null;
};

export default VariantCookie;
