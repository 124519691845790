/**
 * This prefix is used for the variant cookies to avoid possible collisions
 */
export const VARIANT_COOKIE_PREFIX = 'falkor';

/**
 * The default variant ID is used to make the default page sticky
 */
export const DEFAULT_VARIANT_ID = 'default';

/**
 * Given a page variant and a cookie key, returns a page specific cookie name
 * for that particular key.
 *
 * @param variant A page variant object
 * @param key The key of the cookie (suffix)
 * @returns A full name of the cookie
 */
export function variantCookieName(slug: string, key: string): string {
  return `${VARIANT_COOKIE_PREFIX}-${slug}-${key}`;
}
