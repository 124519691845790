import { Criteria } from './types';
import { assert } from '../utils/assert';

/**
 * The isMatchingCriteria utility takes a predicate & test criteria and will
 * return true or false to indicate a match.
 *
 * @param predicate The criteria to satisfy
 * @param test The criteria to test
 * @returns boolean to indicate a match
 */
export function isMatchingCriteria(
  predicate: Criteria,
  test: Criteria,
): boolean {
  Criteria.parse(predicate);
  Criteria.parse(test);

  if (predicate._type !== test._type) {
    return false;
  }

  const type = predicate._type;

  switch (type) {
    case 'employee-count':
      return predicate.value <= (test.value as number);
    case 'company-name':
      return predicate.value.some((name) =>
        (test.value as string[]).includes(name),
      );
    case 'geo-city':
    case 'geo-country':
      // return new RegExp(predicate.value).test(test.value as string);
      return predicate.value === test.value;
    default:
      assert.never(type);
  }

  return false;
}
